import styled from '@emotion/styled'
import { FlexBox } from 'app/components/layout/FlexBox'
import React, { memo } from 'react'

const IndexPage = memo(function IndexPage() {
  return (
    <Container dial={5}>
      <h1>Homepage</h1>
    </Container>
  )
})

const Container = styled(FlexBox)`
  height: 100vh;
`

export default IndexPage
